@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&family=Karla:ital,wght@0,400;0,700;1,400;1,700");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-primary;
    font-family: "Karla";
  }
}

.glow {
  animation: glowing 1s infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #ff0000; 
  }
  50% {
    box-shadow: 0 0 20px #ff0000; 
  }
  100% {
    box-shadow: 0 0 5px #ff0000;
  }
}

